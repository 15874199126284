import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import { APP_STATE } from "../constants";

export const MESSAGES: any = {
  ERROR: "Failed to set credantials",
  WRONG_CRED: "Invalid credentials",
};

interface StatusProps {
  state: string;
}

export function Status({ state }: StatusProps) {
  return (
    <div className="status">
      {state === APP_STATE.ERROR && (
        <Alert severity="error">{MESSAGES["ERROR"]}</Alert>
      )}
      {state === APP_STATE.WRONG_CRED && (
        <Alert severity="error">{MESSAGES["WRONG_CRED"]}</Alert>
      )}
      {state === APP_STATE.LOADING && <CircularProgress />}
    </div>
  );
}
