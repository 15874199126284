import axios from "axios";

function getApiBaseUrl(token: string) {
  return process.env.REACT_APP_API_URL + `/1saas/token?token=${token}`;
}

export async function isOneSaaSKeyValid(apiKey: string, token: string) {
  const response: any = await axios.post(getApiBaseUrl(token), { apiKey });
  return response.data;
}
