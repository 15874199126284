import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import OneSaasLogo from "./assets/1SaaS-logo.svg";
import BlinnoLogo from "./assets/blinno-logo.svg";
import { isOneSaaSKeyValid } from "./services/monday-api";
import { Status } from "./components/Status";
import { APP_STATE } from "./constants";
import "./App.css";

const useStyles = makeStyles(() => ({
  fieldset: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#f5f5f4",
      borderRadius: "10px",
      border: 0,
      boxShadow: "0px 0px 15px 1px rgba(0, 0, 0, 0.36)",
      "&.Mui-focused fieldset": {
        borderColor: "#f6a315",
      },
    },
  },
  button: {
    "&.MuiButton-outlined": {
      backgroundColor: "#f6a315",
      width: "120px",
      color: "#fff",
      border: 0,
      height: "40px",
      fontWeight: "bold",
      fontSize: "16px",
      boxShadow: "0px 0px 15px 1px rgba(0, 0, 0, 0.36)",
      "&:hover": {
        border: 0,
        backgroundColor: "#E9967A",
      },
    },
  },
}));

function App() {
  const classes = useStyles();
  const [mondayJWT, setMondayJWT] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [appState, setAppState] = useState(APP_STATE.DEFAULT);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const { token } = Object.fromEntries(urlSearchParams.entries());
    setMondayJWT(token);
  }, []);

  async function sendOneSaaSKey() {
    if (!inputValue) return;
    try {
      setAppState(APP_STATE.LOADING);
      const { backToUrl } = await isOneSaaSKeyValid(inputValue, mondayJWT);
      setAppState(APP_STATE.DEFAULT);
      window.location.replace(backToUrl);
    } catch (error: any) {
      console.error(error);
      if (error.response.status === 400) {
        setAppState(APP_STATE.WRONG_CRED);
      } else {
        setAppState(APP_STATE.ERROR);
      }
    }
  }

  return (
    <div className="app">
      <div className="container">
        <div className="icons-wrapper">
          <img src={OneSaasLogo} alt="oneSaaS-logo" className="logo" />
          <img src={BlinnoLogo} alt="blinno-logo" className="logo" />
        </div>
        <div className="input-wrapper">
          <label htmlFor="api-key-input" className="input-label">
            API-Key:
          </label>
          <TextField
            id="api-key-input"
            className={classes.fieldset}
            size="small"
            style={{ width: "100%" }}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        </div>
        <div className="tip">
          Get your API Key under{" "}
          <a
            href="https://my.1saas.co/account"
            target="_blank"
            rel="noopener noreferrer"
          >
            my.1saas.co/account
          </a>
        </div>
        <Button
          variant="outlined"
          className={classes.button}
          onClick={sendOneSaaSKey}
          disabled={appState === APP_STATE.LOADING}
        >
          Submit
        </Button>
        {appState !== APP_STATE.DEFAULT && <Status state={appState} />}
      </div>
    </div>
  );
}

export default App;
